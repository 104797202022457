@import "./css/reset.css";
@import "./css/variables.css";


html {
	font-family: 'Nunito Sans', sans-serif;
	font-weight: 300;
	background-color: #fff;
	color: #000;
	font-size: 10px;
}

h1, h2, h3, h4, h5, h6 {
	color: inherit;
	font-family: inherit;
	font-weight: 700;
	margin: 0;
	padding: 0;
}

h1 {
	font-size: 3.3rem;
	margin-bottom: $paragraphSpacing;
	max-width: 6em;
	.pageStyle--none & {
		max-width: none;
	}
}

p {
	color: inherit;
	margin: 0;
	padding: 0;
	line-height: 1.5;
	letter-spacing: 0.05ex;
	font-size: 1.7rem;
	margin-bottom: $paragraphSpacing;
}

a {
	color: #0074DD;
	&:hover {
		text-decoration: underline;
	}
}

::selection {
	background-color: #faefa0;
	color: #000000;
}

.body__more {
	font-weight: 700;
	color: #727272;
}


$overlapMastheadAndBody: calc($spacing * 1);

.masthead {
	position: relative;
	overflow: hidden;
	min-height: 150px;

	display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;

	margin-bottom: calc(-1 * $overlapMastheadAndBody);

	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 32%;
		background: linear-gradient(111.89deg, rgba(255, 49, 0, 1) 0%, rgba(232, 233, 88, 1) 15.48%, rgba(9, 193, 131, 1) 51.02%, rgba(9, 125, 255, 1) 78.43%, rgba(214, 21, 255, 1) 100%);
		transition: background 1000ms $easeFastInMaxOut 0ms;
	}

	.pageStyle--none & {
		min-height: 20px;
		margin-bottom: 20px;
		&::before {
			bottom: 0;
			background: #005DA9;
		}
	}
}

.masthead__body {
	position: relative;
	display: block;
	padding: $pageSpacing;
	padding-bottom: calc($pageSpacing * 0.5);

	margin: 0 auto;
	width: $pageMaxWidth;
	max-width: 100%;

	.pageStyle--none & {
		display: none;
	}
}

.masthead__headshot {
    display: inline-block;
	overflow: hidden;
	border-radius: 100%;
	border: 5px solid #fff;

	margin-bottom: calc($pageSpacing * 0.5);

	width: 260px;
	max-width: 50vw;

	background-size: cover; /* background-image set with style attribute */
    background-position: 50% 50%;
	background-color: #e6e6e6;

	&::before {
		display: block;
		content: "";
		width: 100%;
		padding-bottom: 100%;
	}

	.pageStyle--none & {
		display: none;
	}
}

.masthead__decoration {
	pointer-events: none;
	position: absolute;
	z-index: 0;
	bottom: 0;
	right: 0;
	height: 1px;
	width: 1px;
}
.masthead__decorationImage {
	display: block;
	position: absolute;
	bottom: 0;
	right: calc(-0.5 * $pageSpacing);
	width: 100%;

	width: 30vw;
	max-width: 150px;

	$standardTransform: translateY(calc(-1 * $overlapMastheadAndBody));

	transform: $standardTransform;
	opacity: 0;
	transition: opacity 750ms $easeMaxOut 0ms,
				transform 500ms $easeMaxOut 0ms;

	.pageStyle--2 & {
		transform: translateY(0);
	}
	.pageStyle--5 & {
		transform: $standardTransform translateY(-10%);
	}
	.pageStyle--6 & {
		transform: $standardTransform translateX(25%);
	}
	.pageStyle--7 & {
		transform: translateY(0);
		max-width: 130px;
	}
}

.body {
	display: block;
	padding: $pageSpacing;
	padding-top: calc($pageSpacing * 0.5);
	
	margin: * auto;
	width: $pageMaxWidth;
	max-width: 100%;

	& img {
		max-width: 100%;
	}
}


/* ========================================================================
		Variations based on the pageStyle
 ======================================================================== */
@for $index from 1 to 10 by 1 {
	.pageStyle--$(index) {
		& .masthead__decorationImage--$(index) {
			opacity: 1;
		}
	}
}

.masthead::before {
	/* This is the background to the masthead.
	The default is specified above.*/
	.pageStyle--2 & {
		background-color: #4A314B;
		background-image: none;
	}
	.pageStyle--4 & {
		background-color: #097D7B;
		background-image: none;
	}
	.pageStyle--5 & {
		background-color: #E6C017;
		background-image: none;
	}
	.pageStyle--6 & {
		background-color: #0E57A1;
		background-image: none;
	}
	.pageStyle--7 & {
		background-color: #EE7F22;
		background-image: none;
	}
}